/* eslint-disable max-classes-per-file */
import type {
  Answer,
  Product,
  Property,
  Question,
} from '../model/model.js';
import {
  AnswerPropertyType,
  ChecklistPropertyType,
  OrderPropertyType,
  ProductPropertyType,
  QuestionPropertyType,
  QuestionType,
} from '../model/model.js';
import { Base64 } from '../Utils/base64.js';
import { logEntry } from '../Utils/logger.js';
import { default as ChecklistUtils } from './checklistutils.js';

let _utils: ChecklistUtils;

const is_mobile = false;

interface JSONPropertyType {
  Item?: {
    data?: string;
    NOR?: string;
    [key: string]: any;
  };
  Group?: {
    [key: string]: any;
  };
  Link?: {
    [key: string]: any;
  };
  NOR?: string;
}

export class QuestionHTMLutils {
  constructor() {
    _utils = new ChecklistUtils();
  }

  onChange(question: Question) {
    const _answer = question.properties.find(
      (i) => i.type == QuestionPropertyType.OnChange,
    );
    if (_answer) {
      return _answer.value;
    }
    return '';
  }

  lastchangeby(question: Question, answerNo?: number) {
    const _res: Answer[] = question.answer?.sort(_utils.sortAnswers) || [];
    if (!_res || _res.length == 0) {
      if (question
        && (question._checklist == 'Menu'
          || question._checklist?.match(/^SubMenu/))) {
        return '';
      }
      return 'Not answered';
    }
    if (!answerNo) {
      answerNo = 0;
    } else {
      answerNo = Math.min(answerNo, _res.length - 1);
    }
    const _answer = _res[answerNo].properties.find(
      (i) => i.type == AnswerPropertyType.AnsweredBy,
    );
    const _timestamp = new Date((_res[answerNo].created * 1000)).toLocaleString();
    if (_answer) {
      return `${_answer.value} ${_timestamp}`;
    }
    return 'Server' + ` ${_timestamp}`;
  }

  questionText(question: Question): string {
    let _text = question.properties.find(
      (i) => i.type == QuestionPropertyType[window.activelanguage],
    );
    if (!_text) {
      _text = question.properties.find((i) => i.type == QuestionPropertyType.ENG);
    }
    if (!_text) {
      _text = question.properties.find((i) => i.type == QuestionPropertyType.NOR);
    }
    if (_text) {
      return `<label for="qId-${question.id}">${_text.value as string}</label>`;
    }
    return '';
  }

  questionData(question: Question) {
    const _data = question.properties.filter(
      (i) => i.type == QuestionPropertyType.Data,
    );
    if (_data) {
      return _data;
    }
    return null;
  }

  questionType(question: Question): string {
    const _type = question.properties.find(
      (i) => i.type == QuestionPropertyType.Type,
    );
    if (_type) {
      return _type.value as string;
    }
    return '';
  }

  questionAnswer(question: Question, answerNo?: number): string {
    const _ans = question.answer?.filter((a) => a.seq_no == question._seqno && a.created >= 0);
    const _res: Answer[] = _ans?.sort(_utils.sortAnswers) || [];
    if (!_res || _res.length == 0) {
      return '';
    }
    if (!answerNo) {
      answerNo = 0;
    } else {
      answerNo = Math.min(answerNo, _res.length - 1);
    }
    const _answer = _res[answerNo].properties.find(
      (i) => i.type == AnswerPropertyType.Answer,
    );
    if (_answer) {
      return _answer.value.toString();
    }
    return '';
  }

  instance(question: Question): number {
    return _utils.instance(question);
  }

  required(question: Question) {
    const _property = question.properties.find(
      (i) => i.type == QuestionPropertyType.Required,
    );
    try {
      if (_property && _property.value.toString().length) {
        return eval(_property.value as string);
      }
    } catch (e) {
      logEntry(`${JSON.stringify(e)} ${e}`);
      console.log(`required error: ${question.id}${(e as Error).message}`);
    }
    return false;
  }

  readonly(question: Question): boolean {
    const _property = question.properties.find(
      (i) => i.type == QuestionPropertyType.WriteProtected,
    );

    try {
      if (question._checklistid == 1) { // CP2 checklist, always read only
        return true;
      }
      if (_property && _property.value.toString().length) {
        return eval(_property.value as string);
      }
    } catch (e) {
      logEntry(`${JSON.stringify(e)} ${e}`);
      console.log(`readonly error: ${question.id}${(e as Error).message}`);
    }
    return false;
  }

  HTMLheader(question: Question): string {
    const ID = ` type=${question.properties.find(
      (i) => i.type == QuestionPropertyType.Type,
    )?.value || ''
    } checklist=${question._checklist
    } checklistid=${question._checklistid
    } questionid=${question.id
    } category=${question.properties.find((i) => i.type == QuestionPropertyType.Category)
      ?.value || ''
    } seqno=${question.properties.find(
      (i) => i.type == QuestionPropertyType.SequenceNumber,
    )?.value || ''}`;

    const _type = question.properties.find(
      (i) => i.type == QuestionPropertyType.Type,
    );

    if (!_type) {
      return '<div>';
    }

    let serverUpdated = '';
    if (question.answer?.find((i) => i.id < 1)) {
      serverUpdated = ' data-serverupdated="no"';
    }

    if (this.readonly(question)) {
      serverUpdated = ' data-readonly="true"';
    }

    const header = `<div class="question" ${
      // ' title="' + this.lastchangeby(question)  + ID + '" '+
      this.answeredStyle(question)
    }${serverUpdated
    }" style="${this.getQuestionStyle(question)
    }">`
      + `<hr>${this.questionText(question)}`;

    return header + (this.questionText(question).length ? '<br>' : '');
  }

  HTMLFooter(question: Question, answerNo?: number): string {
    // return '</div>';
    return (
      `<br><small style="border-left:0px" ><label id="labelansweredby">${this.lastchangeby(question, answerNo)
      }</label></small></div>`
    );
  }

  showOnlyInstance(question: Question): void {
    const instance = this.instance(question);
    const orderID = question._order_id || 0;

    for (let i = 0; i < window.Data.order[orderID].item.length; i++) {
      const item = window.Data.order[orderID].item[i];
      for (let j = 0; j < item.product.length; j++) {
        const product = item.product[j];
        for (let k = 0; k < product.checklist.length; k++) {
          const checklist = product.checklist[k];
          if (checklist.question) {
            for (let l = 0; l < checklist.question.length; l++) {
              const q = checklist.question[l];
              const qtype = parseInt(
                (q.properties.find((i) => i.type == QuestionPropertyType.Type)
                  ?.value as string) || '0',
              );
              if (
                qtype == QuestionType.Groupheading
                && instance != question._itemid
              ) {
                let seq_no = 0;
                const value = question.properties.find((p) => p.type == QuestionPropertyType.SequenceNumber)?.value;
                if (value) {
                  const seqNo = parseInt(value.toString());
                  if (seqNo) {
                    seq_no = seqNo;
                  } else {
                    console.error(`Sequence number not found for question >${question.id}<`);
                  }
                }
                const q2 = {
                  dataset: {
                    questionid: q.id.toString(),
                    seqno: seq_no.toString(),
                    itemid: q._itemid?.toString() || '',
                    productid: q._product?.toString() || '',
                    orderid: q._order_id?.toString() || '',
                    checklistid: q._checklistid?.toString() || '',
                    checklist: q._checklist?.toString() || '',
                  },
                };
                _utils.updateAnswer(q2, '1');
              }
            }
          }
        }
      }
    }
  }

  confirmDelete(question: Question): void {
    const instance = this.instance(question);
    console.log(`confirmDelete: ${question.id} ${instance}`);
  }

  getQuestionStyle(question: Question): string {
    const _css = question.properties.find(
      (i) => i.type == QuestionPropertyType.CSS,
    );
    if (_css) {
      return _css.value.toString();
    }
    // get CSS from parent checklistid since there is no CSS for this question
    const _checklist = question._checklistid;
    const c_CSS = window.Data.checklist
      ?.find((i) => i.id == _checklist)
      ?.properties.find((i) => i.type == ChecklistPropertyType.CSS)
      ?.value.toString();
    if (c_CSS) {
      return c_CSS;
    }

    // get CSS from parent orderid since there is no CSS in checklist for this question
    const _orderid = question._order_id;
    const o_CSS = window.Data.order
      .find((i) => i.id == _orderid)
      ?.properties.find((i) => i.type == ChecklistPropertyType.CSS)
      ?.value.toString();
    return o_CSS || '';
  }

  answeredStyle(q: Question): string {
    const InfoTypes = [4, 5, 7, 8, 18, 22, 23, 25];
    const qType = q.properties.find(
      (i) => i.type == QuestionPropertyType.Type,
    ) || { value: '-999' };
    if (this.readonly(q)) {
      return 'data-answered="yes"';
    }

    if (q.answer?.length) {
      return 'data-answered="yes"';
    }

    if (InfoTypes.includes(parseInt(qType.value as string, 10))) {
      return 'data-answered="optional"';
    }

    if (this.required(q)) {
      return 'data-answered="no"';
    }

    return 'data-answered="optional"';
  }
}

export class QuestionHTML extends QuestionHTMLutils {
  text(ID: string, question: Question, answerNo?: number) {
    const displayAnswer = this.questionAnswer(question, answerNo);
    const HTML = `${this.HTMLheader(question)
    }<input size="${displayAnswer.toString().length > 20
      ? displayAnswer.toString().length + 3
      : '20'
    }" class="q" data-question="q" type="text" ${ID
    } value="${displayAnswer
    }" oninput="${this.onChange(question)
    }" onBlur="utils.updateAnswer(this, this.value);">${this.HTMLFooter(question, answerNo)}`;
    return HTML;
  }

  number(ID: string, question: Question, answerNo?: number) {
    const displayAnswer = this.questionAnswer(question, answerNo);
    const HTML = `${this.HTMLheader(question)
    }<input size="${displayAnswer.toString().length > 20
      ? displayAnswer.toString().length + 3
      : '20'
    }" class="q" data-question="q" type="number" min="0" ${ID
    } value="${displayAnswer
    }" oninput="${this.onChange(question)
    }" onBlur="utils.updateAnswer(this, this.value);">${this.HTMLFooter(question, answerNo)}`;
    return HTML;
  }

  check(ID: string, question: Question, answerNo?: number) {
    const HTML = `${this.HTMLheader(question)
    }<input class="q" data-question="q" type="checkbox" ${ID
    } ${this.questionAnswer(question, answerNo) == '1' ? ' checked ' : ''
    } oninput="${this.onChange(question)
    }" onclick="utils.updateAnswer(this,(this.checked===true? 1:0));document.activeElement.blur();">${this.HTMLFooter(question, answerNo)}`;
    return HTML;
  }

  dropdown(ID: string, question: Question, answerNo?: number) {
    let HTML = `${this.HTMLheader(question)
    }<select class="q" data-question="q" ${ID
    } onChange="utils.updateAnswer(this,this.value);this.blur();">`;
    const maxlength = 1000;
    let containsdata = false;

    const data = this.questionData(question);

    if (!this.questionAnswer(question, answerNo)) {
      HTML
        += `<option value="${this.questionAnswer(question, answerNo)
        }" selected>${this.questionAnswer(question, answerNo)
        }</option>`;
    }

    const checklistdata = data && data.length ? data : ([] as Property[]);
    let cost = 0;

    for (let i = 0; i < checklistdata.length; i++) {
      const item = checklistdata[i];
      let JSONproperty: JSONPropertyType = {};
      try {
        JSONproperty = JSON.parse(item.value as string);
      } catch (e) {
        logEntry(`${JSON.stringify(e)} ${e}`);
        console.log(`dropdown error: ${question.id}${(e as Error).message}`);
      }
      cost = 0;
      if (JSONproperty && JSONproperty.Item && JSONproperty.Item.data) {
        const prod = _utils.getProduct(JSONproperty.Item.data.toString()) as Product;
        if (prod) {
          cost = (prod.properties.find((p) => p.type == ProductPropertyType.PriceExVAT)?.value as number) * ((prod.properties.find((p) => p.type == ProductPropertyType.VAT)?.value as number || 25) + 100) / 100;
        }
      }
      try {
        if (typeof JSONproperty.Group === 'object') {
          HTML
            += `</optgroup><optgroup label="${JSONproperty.Group[window.activelanguage]
              ? JSONproperty.Group[window.activelanguage].substr(0, maxlength)
              : JSONproperty.Group.NOR.substr(0, maxlength)
            }">`;
        }
        if (typeof JSONproperty.Item === 'object') {
          HTML
            += `<option value="${JSONproperty.Item.data || JSONproperty.Item.NOR
            }" ${this.questionAnswer(question, answerNo) == (JSONproperty.Item.data || JSONproperty.Item.NOR)
              ? ' selected '
              : ''
            }>${JSONproperty.Item[window.activelanguage]
              ? JSONproperty.Item[window.activelanguage].substr(0, maxlength)
              : JSONproperty.Item.NOR?.substr(0, maxlength)
            }${cost > 0 ? ` ( ${cost.toFixed(0)} kr )` : ''
            }</option>`;
          containsdata = true;
        }
      } catch (e) {
        logEntry(`${JSON.stringify(e)} ${e}`);
        console.log(`dropdown error: ${question.id}${(e as Error).message}`);
      }
    }

    HTML += `</select>${this.HTMLFooter(question, answerNo)}`;
    return containsdata ? HTML : '';
  }

  picture(ID: string, question: Question, answerNo?: number) {
    const src = this.questionAnswer(question, answerNo).toString().length < 255
      ? this.questionAnswer(question, answerNo)
      : this.questionAnswer(question, answerNo);
    const HTML = `${this.HTMLheader(question)
    }<div class="imgwrapper"><img data-question="q" class="zoomimage" ${this.questionAnswer(question, answerNo).toString().length ? '' : 'style="display:none" '
    }${ID
    } src="${src
    }" alt="Bilde" width="100%" onchange="${this.onChange(question)
    }" ></div>`
      + `<input type="file" accept="image/*" ${ID
      } onchange="utils.updateAnswer(this,this.value);"${this.HTMLFooter(question, answerNo)}`;
    return HTML;
  }

  multiplepicture(ID: string, question: Question, answerNo?: number) {
    const HTML = `${this.HTMLheader(question)
    }<div class="imgwrapper"><img data-question="q" class="zoomimage" ${this.questionAnswer(question, answerNo).toString().length ? '' : 'style="display:none" '
    }${ID
    } src="${this.questionAnswer(question, answerNo)
    }" alt="Bilde" width="100%" onchange="${this.onChange(question)
    }" ></div>`
      + `<button class="menubutton" style="font-size : 150%;font-family: LigatureSymbols;visibility:${!this.readonly(question) ? 'visible' : 'hidden'
      };" type="button" ${ID
      } onclick="window.utils.duplicateQuestion(this);">&#xE038;</button>`
      + `<input type="file" accept="image/*" ${ID
      } onchange="utils.updateAnswer(this,this.value);" ${this.HTMLFooter(question, answerNo)}`;
    return HTML;
  }

  installpicture(ID: string, question: Question, answerNo?: number) {
    let HTML = `${this.HTMLheader(question)
    }<div class="imgwrapper"><img data-question="q" class="zoomimage" ${this.questionAnswer(question, answerNo).toString().length ? '' : 'style="display:none" '
    }${ID
    } src="${this.questionAnswer(question, answerNo)
    }" alt="Bilde" width="100%" onchange="${this.onChange(question)
    }" ></div>`;

    HTML
      += `<canvas id="canvasTemp" ${ID} width="80%" height="80%" style="display:none"> </canvas>
       <input ${ID} type="file" accept="image/*" onclick="" onchange="window.annotate.installpict(this);"><br>
       <button class="menubutton" style="font-size : 150%;" type="button" onclick="window.annotate.drawer.drawingFreehand(false);">|_|</button>
       <button class="menubutton" style="font-size : 150%;" type="button" onclick="window.annotate.drawer.drawingFreehand(true);">&#1705;&#xFE0E;</button>
       <button class="menubutton" style="font-size : 150%;" type="button" onclick="window.annotate.installpict(this);">&#8630;</button>
       <button class="menubutton" style="font-size : 150%;font-family: LigatureSymbols;" type="button" onclick="window.utils.holdRendering=false;window.utils.renderall();">&#xE10f;</button>
       <button class="menubutton" style="font-size : 150%;" type="button" ${ID} onclick="if (this.parentElement?.getElementsByTagName('canvas')[0].style.display!=='none') {
       window.utils.updateAnswer(this, this.parentElement?.getElementsByTagName('canvas')[0]); 
       window.utils.holdRendering=false;
      }">OK</button>${this.HTMLFooter(question, answerNo)}`;
    window.annotate.drawer.drawingFreehand(false);
    return HTML;
  }

  multipleinstallpicture(ID: string, question: Question, answerNo?: number) {
    let HTML = `${this.HTMLheader(question)
    }<div class="imgwrapper"><img data-question="q" class="zoomimage" ${this.questionAnswer(question, answerNo).toString().length ? '' : 'style="display:none" '
    }${ID
    } src="${this.questionAnswer(question, answerNo)
    }" alt="Bilde" width="100%" onchange="${this.onChange(question)
    }" ></div>`;
    HTML
      += `<canvas id="canvasTemp" ${ID
      } width="80%" height="80%" style="display:none"> </canvas>`
      + `<input ${ID
      } type="file" accept="image/*" onclick="" onchange="window.annotate.installpict(this);"><br>\n\
      <button class="menubutton" style="font-size : 150%;font-family: LigatureSymbols;visibility:${!this.readonly(question) ? 'visible' : 'hidden'
};" type="button" ${ID
} onclick="window.utils.duplicateQuestion(this);">&#xE038;</button>\n\
      <button class="menubutton" style="font-size : 150%;" type="button" onclick="window.annotate.drawer.drawingFreehand(false);">|_|</button>\n\
      <button class="menubutton" style="font-size : 150%;" type="button" onclick="window.annotate.drawer.drawingFreehand(true);">&#1705;&#xFE0E;</button>\n\
      <button class="menubutton" style="font-size : 150%;" type="button" onclick="window.annotate.installpict(this);">&#8630;</button>\n\
      <button class="menubutton" style="font-size : 150%;font-family: LigatureSymbols;" type="button" onclick="window.utils.holdRendering=false;window.utils.renderall();">&#xE10f;</button>\n\
      <button class="menubutton" style="font-size : 150%;" type="button" ${ID
}\n\
      onclick="if (this.parentElement?.getElementsByTagName('canvas')[0].style.display!=='none') {window.utils.updateAnswer(this, this.parentElement?.getElementsByTagName('canvas')[0]); window.utils.holdRendering=false;}">OK</button>${this.HTMLFooter(question, answerNo)}`;
    window.annotate.drawer.drawingFreehand(false);
    return HTML;
  }

  information(ID: string, question: Question, answerNo?: number) {
    const HTML = this.HTMLheader(question) + this.HTMLFooter(question, answerNo);
    return HTML;
  }

  header(ID: string, question: Question, answerNo?: number) {
    const HTML = this.HTMLheader(question) + this.HTMLFooter(question, answerNo);
    return HTML;
  }

  file(ID: string, question: Question, answerNo?: number) {
    let filename = '';
    let filedata = '';
    if (this.questionAnswer(question, answerNo).length) {
      try {
        filedata = JSON.parse(this.questionAnswer(question, answerNo)).data;
        filename = JSON.parse(this.questionAnswer(question, answerNo)).filename;
      } catch (e) {
        logEntry(`${JSON.stringify(e)} ${e}`);
        filedata = this.questionAnswer(question, answerNo);
        filename = this.questionAnswer(question, answerNo);
      }
    }
    const HTML = `${this.HTMLheader(question)
    }<a onclick="window.open('${filedata || ''
    }${is_mobile ? '\', \'_system\');">' : '\', \'_blank\');">'
    }${filename
    }</a>`
      + '<form>'
      + `<input type="file" accept="*/*"${ID
      } onchange="utils.updateAnswer(this,this.value);"</form>${this.HTMLFooter(question, answerNo)}`;
    return HTML;
  }

  // {\"filename\":\"EcoSmart luft-vann varmepumpe brosjyre R01.pdf\",\"data\":\"data:application/pdf;base64,

  htmllink(ID: string, question: Question, answerNo?: number) {
    const HTML = this.htmllinks(ID, question, answerNo);
    return HTML;
  }

  htmllinks(ID: string, question: Question, answerNo?: number) {
    // {"Type":"HTMLlinks","Item1":{"NOR":"Produktark","ENG":"Product datasheets","POL":"","Link":"https://kontor.acsenteret.no:442/ProduktArk/"},"Item2":{"NOR":"Servicemanualer","ENG":"Service manuals","POL":"","Link":"https://kontor.acsenteret.no:442/Servicemanualer"},"Item3":{"NOR":"Bruksanvisninger","ENG":"User manuals","POL":"","Link":"https://kontor.acsenteret.no:442/Bruksanvisninger"},"Item4":{"NOR":"Instruksjonsvideo","ENG":"Instruction video","POL":"","Link":"https://kontor.acsenteret.no:442/Servicemanualer/Instruksjonsvideo/ACsenteret instruksjonsfilm.mp4"}}
    const data = this.questionData(question);
    let HTML = this.HTMLheader(question);
    const checklistdata = data && data.length ? data : ([] as Property[]);

    for (let dataitem = 0; dataitem < checklistdata.length; dataitem++) {
      let JSONproperty: JSONPropertyType = {};
      try {
        JSONproperty = JSON.parse(checklistdata[dataitem].value as string);
      } catch (e) {
        logEntry(`${JSON.stringify(e)} ${e}`);
        console.log(
          `htmllinks: ${question.id
          } ${(e as Error).message
          } ${dataitem}`,
        );
      }

      if (Object.keys(JSONproperty).length) {
        HTML
          += `<button onclick="window.open('${JSONproperty.Link ? JSONproperty.Link : ''
          }${is_mobile ? '\', \'_system\');">' : '\', \'_blank\');">'
          }${typeof JSONproperty[window.activelanguage] === 'undefined'
            || JSONproperty[window.activelanguage] === ''
            ? JSONproperty.NOR
            : JSONproperty[window.activelanguage]
          }</button>`;
      }
    }
    HTML += this.HTMLFooter(question, answerNo);
    return HTML;
  }

  productline(ID: string, question: Question, answerNo?: number) {
    let HTML = this.HTMLheader(question);
    const displayAnswer = this.questionAnswer(question, answerNo);

    // if property WriteProtected exists, set it to '0'
    const writeOnlyProperty = question.properties.find(
      (i) => i.type === QuestionPropertyType.WriteProtected,
    );
    if (writeOnlyProperty) {
      writeOnlyProperty.value = '0';
    }

    try {
      let JSONproperty: { id: number; qty: number; discount: number; note: string; price?: number; };
      const answerString = this.questionAnswer(question, answerNo);
      let discount = 0;
      if (answerString.length) {
        JSONproperty = JSON.parse(this.questionAnswer(question, answerNo).toString());
        if (Object.keys(JSONproperty).length) {
          const product = window.Data.product.find((p) => p.id === JSONproperty.id);
          const customProduct = product?.properties.find((pr) => pr.type === ProductPropertyType.Custom);
          if (customProduct) {
            return '';
          }
          const productERPno = product?.properties.find((pr) => pr.type === ProductPropertyType.ERPprodid)?.value || product?.properties.find((pr) => pr.type === ProductPropertyType.MRP24SOProdid)?.value;
          if (!productERPno) {
            return '';
          }
          const productNumber = product?.properties.find((pr) => pr.type === ProductPropertyType.MRP24SOProdid)?.value.toString().trim();
          let productname = product?.properties.find((pr) => pr.type === ProductPropertyType.Name)?.value.toString().trim();
          if (productNumber && !productname?.includes(productNumber)) {
            productname = `${productname} - ${productNumber}`;
          }
          const productprice = product?.properties.find((pr) => pr.type === ProductPropertyType.PriceExVAT)?.value as number * 1;
          const priceForLine = JSONproperty.price || productprice;
          // const productcost = product?.properties.find(pr => pr.type === ProductPropertyType.CostExVAT)?.value as number * 1;
          const productvat = product?.properties.find((pr) => pr.type === ProductPropertyType.VAT)?.value as number * 1 || 25;
          const qty = JSONproperty.qty as number * 1.0 || 0;
          if (JSONproperty.discount) {
            discount = JSONproperty.discount as number * 1.0 || 0;
          }
          const unit = product?.properties.find((pr) => pr.type === ProductPropertyType.Unit)?.value || '';
          // set qty to max 3 decimals
          const qtyPretty = Math.round(qty * 1000) / 1000;
          if (!JSONproperty.note) {
            JSONproperty.note = '';
          }
          // Check orderproperty if DetailedOrderline=1
          const order = window.Data.order.find((o) => o.id === question._order_id);
          const detailedOrderline = order?.properties.find((p) => p.type === OrderPropertyType.DetailedOrderline) || { value: 0 };
          HTML
            += `${productname} kr ${(((priceForLine) * (100 + productvat)) / 100).toFixed(0)} x ${qtyPretty} ${unit}`;
          // HTML
          //   += ` <button class="menubutton" id="qCancel" ${ID
          //   } type="button" style="font-size : 150%;font-family: LigatureSymbols;"
          // onclick="(async () => {await utils.removeOldProductsProductline(this, ${product?.id});
          // utils.updateAnswer(this,'0');utils.holdRendering=false;await utils.renderall();})();">&#xE10f;</button>`;
          HTML += `<br>${window.activelanguage === 'NOR' ? 'Rabatt' : 'Discount'} <input size="${displayAnswer.toString().length > 10
            ? displayAnswer.toString().length + 3
            : '10'
          }" class="q" data-question="q" type="number" ${ID
          } value="${discount
          }" oninput="`
          + `" onBlur="utils.updateAnswer(this, '{&quot;id&quot;: ${JSONproperty.id}, &quot;qty&quot;: ${JSONproperty.qty}, &quot;discount&quot;: '+this.value*1+', &quot;note&quot;: &quot;${JSONproperty.note}&quot;}');">`;
          if (detailedOrderline.value) {
            const encodedQtyPretty = encodeURIComponent(qtyPretty);
            const decodedNote = decodeURIComponent(JSONproperty.note);
            const inputSize1 = JSONproperty.qty.toString().length > 6 ? JSONproperty.qty.toString().length + 2 : 6;
            const inputSize2 = decodedNote.toString().length > 20 ? decodedNote.toString().length + 2 : 20;
            HTML += `<br>Qty:<input size="${inputSize1}" class="q" data-question="q" type="number" ${ID} value="${encodedQtyPretty}" oninput="" onBlur="utils.updateAnswer(this, '{&quot;id&quot;: ${JSONproperty.id}, &quot;qty&quot;: '+this.value*1+', &quot;discount&quot;: ${discount}, &quot;note&quot;: &quot;${JSONproperty.note}&quot;}');">`;
            HTML += `<br>Text:<input size="${inputSize2}" class="q" data-question="q" type="text" ${ID} value="${decodedNote}"        oninput="" onBlur="utils.updateAnswer(this, '{&quot;id&quot;: ${JSONproperty.id}, &quot;qty&quot;: ${qtyPretty}, &quot;discount&quot;: ${discount}, &quot;note&quot;: &quot;' + encodeURIComponent(this.value) + '&quot;}');">`;
            // HTML += `<br><textarea placeholder="" data-question="q" ${ID} cols="80" rows="8" onBlur="utils.updateAnswer(this, '{&quot;id&quot;: ${JSONproperty.id}, &quot;qty&quot;: ${JSONproperty.qty}, &quot;discount&quot;: ${discount}, &quot;note&quot;: &quot;' + encodeURIComponent(this.value) + '&quot;}');" value="${decodedNote}">${decodedNote}</textarea>`;
          }
        } else {
          return '';
        }
      } else {
        return '';
      }
    } catch (e) {
      logEntry(`${JSON.stringify(e)} ${e}`);
      console.log(`productline: ${(e as Error).message}`);
      return '';
    }
    HTML += this.HTMLFooter(question, answerNo);
    return HTML;
  }

  date(ID: string, question: Question, answerNo?: number) {
    const HTML = `${this.HTMLheader(question)
    }<input class="q" data-question="q" type="date"${ID
    } value="${this.questionAnswer(question, answerNo) ? this.questionAnswer(question, answerNo) : ''
    }" oninput="${this.onChange(question)
    }" onBlur="utils.updateAnswer(this,this.value);">${this.HTMLFooter(question, answerNo)}`;
    return HTML;
  }

  datetime(ID: string, question: Question, answerNo?: number) {
    const HTML = `${this.HTMLheader(question)
    }<input class="q" data-question="q" type="datetime-local"${ID
    } value="${this.questionAnswer(question, answerNo) ? this.questionAnswer(question, answerNo) : ''
    }" oninput="${this.onChange(question)
    }" onBlur="utils.updateAnswer(this,this.value);">${this.HTMLFooter(question, answerNo)}`;
    return HTML;
  }

  email(ID: string, question: Question, answerNo?: number) {
    const displayAnswer = this.questionAnswer(question, answerNo);
    const HTML = `${this.HTMLheader(question)
    }<input size="${displayAnswer.toString().length > 20
      ? displayAnswer.toString().length + 3
      : '20'
    }" class="q" data-question="q" type="text" ${ID
    } value="${displayAnswer
    }" oninput="${this.onChange(question)
    }" onBlur="if (/^[a-zA-Z0-9.!#$%&'*+/=?^_\`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/.test(this.value)) utils.updateAnswer(this, this.value); else { if (this.value.length>0) {utils.highlight(this);this.focus();} else utils.renderall()}">${this.HTMLFooter(question, answerNo)}`;
    return HTML;
  }

  button(ID: string, question: Question, answerNo?: number) {
    const HTML = this.buttons(ID, question, answerNo);
    return HTML;
  }

  buttons(ID: string, question: Question, answerNo?: number) {
    let HTML = this.HTMLheader(question);
    const data = this.questionData(question);
    const displayAnswer = this.questionAnswer(question, answerNo);

    let answerfound = false;
    const checklistdata = data && data.length ? data : ([] as Property[]);

    for (let dataitem = 0; dataitem < checklistdata.length; dataitem++) {
      let JSONproperty: JSONPropertyType = {};
      try {
        JSONproperty = JSON.parse(checklistdata[dataitem].value as string);
      } catch (e) {
        logEntry(`${JSON.stringify(e)} ${e}`);
        console.log(
          `buttons: ${question.id
          } ${(e as Error).message
          } ${dataitem}`,
        );
      }

      if (Object.keys(JSONproperty).length) {
        answerfound = true;
        HTML
          += `<button id="qButton"  type="button" ${ID
          }${displayAnswer
            == (typeof JSONproperty[window.activelanguage] === 'undefined'
              || JSONproperty[window.activelanguage] === ''
              ? JSONproperty.NOR
              : JSONproperty[window.activelanguage])
            ? 'data-answered="yes"'
            : ''
          } onclick="${this.onChange(question)
          };utils.updateAnswer(this,'${JSONproperty.NOR
          }');">${typeof JSONproperty[window.activelanguage] === 'undefined'
            || JSONproperty[window.activelanguage] === ''
            ? JSONproperty.NOR
            : JSONproperty[window.activelanguage]
          }</button>`;
      }
    }

    if (!answerfound && displayAnswer.toString().length) {
      HTML
        += `<button id="qButton"  type="button" ${ID
        } data-answered="yes" onclick="${this.onChange(question)
        };utils.updateAnswer(this,'');">${displayAnswer
        }</button>`;
    }

    HTML += this.HTMLFooter(question, answerNo);
    return HTML;
  }

  fsm(ID: string, question: Question, answerNo?: number) {
    const HTML = `${this.HTMLheader(question)
    }<button \n\
                        id="qButton" \n\
                        type="button" \n\
                        ${ID
}\n\
                        onclick="${this.onChange(question)
}" touchstart="${this.onChange(question)
}">${this.questionText(question)
}</button>${this.HTMLFooter(question, answerNo)}`;
    return HTML;
  }

  signature(ID: string, question: Question, answerNo?: number) {
    let answer = this.questionAnswer(question, answerNo);
    if (answer.toString().match(/^W3sibHgiO/)) {
      answer = _utils.drawSignature(Base64.decode(answer as string) as string);
    }
    let HTML = `${this.HTMLheader(question)
    }<div class="imgwrapper"><img data-question="q" class="zoomimage" ${this.questionAnswer(question, answerNo).toString().length ? '' : 'style="display:none" '
    }${ID
    } src="${answer
    }" alt="Bilde" width="100%" onchange="${this.onChange(question)
    }" ></div>`;
    HTML
      += `<canvas class="sigPad${question.id
      }${question.properties.find((i) => i.type == QuestionPropertyType.Category)
        ?.value || ''
      }${_utils.instance(question)
      }${question.properties.find(
        (i) => i.type == QuestionPropertyType.SequenceNumber,
      )?.value || ''
      }" width="${self.innerWidth - 32
      }" ${this.questionAnswer(question, answerNo).toString().length ? 'style="display:none" ' : ''
      } height="105"></canvas>`;

    HTML
      += '<br><button class="menubutton" type="button" style="font-size : 150%;font-family: LigatureSymbols;" onclick="utils.signaturePad(this);">&#xE09f;</button>';
    HTML
      += ` <button class="menubutton" id="qCancel" ${ID
      } type="button" style="font-size : 150%;font-family: LigatureSymbols;" onclick="utils.holdRendering=false;utils.renderall();">&#xE10f;</button>`;

    HTML
      += ` <button class="menubutton" id="qOK" ${ID
      } type="button" style="font-size : 150%;" onclick="if (utils.signatureOK()) if (this.parentElement?.getElementsByTagName('canvas')[0].style.display!=='none') {utils.updateAnswer(this, this.parentElement?.getElementsByTagName('canvas')[0]); utils.holdRendering=false;}">OK</button>`;

    HTML += this.HTMLFooter(question, answerNo);
    return HTML;
  }

  note(ID: string, question: Question, answerNo?: number) {
    const HTML = `${this.HTMLheader(question)
    }<textarea placeholder="" data-question="q" ${ID
    } cols="40" rows="8" onchange="${this.onChange(question)
    }" onBlur="utils.updateAnswer(this,this.value);" value="${this.questionAnswer(question, answerNo)
    }">${this.questionAnswer(question, answerNo)
    }</textarea>${this.HTMLFooter(question, answerNo)}`;
    return HTML;
  }

  htmlnote(ID: string, question: Question, answerNo?: number) {
    const HTML = `${this.HTMLheader(question)
    }<textarea placeholder="" data-question="q" ${ID
    } cols="40" rows="8" onchange="${this.onChange(question)
    }" onBlur="utils.updateAnswer(this,this.value);" value="${this.questionAnswer(question, answerNo)
    }">${this.questionAnswer(question, answerNo)
    }</textarea>${this.HTMLFooter(question, answerNo)}`;
    return HTML;
  }

  tableview(ID: string, question: Question, answerNo?: number) {
    const HTML = `${this.HTMLheader(question)
    }<textarea placeholder="" data-question="q" ${ID
    } cols="40" rows="8" onchange="${this.onChange(question)
    }" onBlur="utils.updateAnswer(this,this.value);" value="${this.questionAnswer(question, answerNo)
    }">${this.questionAnswer(question, answerNo)
    }</textarea>${this.HTMLFooter(question, answerNo)}`;
    return HTML;
  }

  livetable(ID: string, question: Question, answerNo?: number) {
    const HTML = `${this.HTMLheader(question)
    }<textarea placeholder="" data-question="q" ${ID
    } cols="40" rows="8" onchange="${this.onChange(question)
    }" onBlur="utils.updateAnswer(this,this.value);" value="${this.questionAnswer(question, answerNo)
    }">${this.questionAnswer(question, answerNo)
    }</textarea>${this.HTMLFooter(question, answerNo)}`;
    return HTML;
  }

  approve(ID: string, question: Question, answerNo?: number) {
    let HTML = this.HTMLheader(question);
    if (this.questionAnswer(question, answerNo) === 'Yes') {
      HTML
        += `<button class="menubutton" style="font-size : 150%;color:green;"\n\
                        id="qButton" \n\
                        type="button" \n\\n\
                        data-answered="yes" \n\
                        ${ID
}\n\
                        onclick="utils.updateAnswer(this,'Yes');">&#128077;</button>`;
    } else {
      HTML
        += `<button class="menubutton" style="font-size : 150%;color:green;"\n\
                        id="qButton" \n\
                        type="button" \n\
                        ${ID
}\n\
                        onclick="utils.updateAnswer(this,'Yes');">&#128077;</button>`;
    }
    if (this.questionAnswer(question, answerNo) === 'No') {
      HTML
        += `<button class="menubutton" style="font-size : 150%;color:red;"\n\
                        id="qButton" \n\
                        type="button" \n\\n\
                        data-answered="yes" \n\
                        ${ID
}\n\
                        onclick="utils.updateAnswer(this,'No');">&#128078;</button>`;
    } else {
      HTML
        += `<button class="menubutton" style="font-size : 150%;color:red;"\n\
                        id="qButton" \n\
                        type="button" \n\
                        ${ID
}\n\
                        onclick="utils.updateAnswer(this,'No');">&#128078;</button>`;
    }

    HTML += this.HTMLFooter(question, answerNo);
    return HTML;

    // <input type="radio" name="gender" value="male"> Male<br>
  }

  yesno(ID: string, question: Question, answerNo?: number) {
    let HTML = this.HTMLheader(question);
    if (this.questionAnswer(question, answerNo) == '1' || this.questionAnswer(question, answerNo) == 'Yes') {
      HTML
        += `<button class="menubutton" style="font-size : 150%;color:green;" id="qButton" type="button" data-answered="yes" ${ID
        } onclick="utils.updateAnswer(this,1);">&#128077;</button>`;
    } else {
      HTML
        += `<button class="menubutton" style="font-size : 150%;color:green;" id="qButton" type="button" ${ID
        } onclick="utils.updateAnswer(this,1);">&#128077;</button>`;
    }
    if (this.questionAnswer(question, answerNo) == '0' || this.questionAnswer(question, answerNo) == 'No') {
      HTML
        += `<button class="menubutton" style="font-size : 150%;color:red;" id="qButton" type="button" data-answered="yes" ${ID
        } onclick="utils.updateAnswer(this,0);">&#128078;</button>`;
    } else {
      HTML
        += `<button class="menubutton" style="font-size : 150%;color:red;" id="qButton" type="button" ${ID
        } onclick="utils.updateAnswer(this,0);">&#128078;</button>`;
    }

    HTML += this.HTMLFooter(question, answerNo);
    return HTML;

    // <input type="radio" name="gender" value="male"> Male<br>
  }

  yesnomaybe(ID: string, question: Question, answerNo?: number) {
    let HTML = this.HTMLheader(question);
    if (this.questionAnswer(question, answerNo) == '1') {
      HTML
        += `<button class="menubutton" style="font-size : 150%;color:green;"\n\
                        id="qButton" \n\
                        type="button" \n\\n\
                        data-answered="yes" \n\
                        ${ID
}\n\
                        onclick="utils.updateAnswer(this,1);">&#128077;</button>`;
    } else {
      HTML
        += `<button class="menubutton" style="font-size : 150%;color:green;"\n\
                        id="qButton" \n\
                        type="button" \n\
                        ${ID
}\n\
                        onclick="utils.updateAnswer(this,1);">&#128077;</button>`;
    }
    if (this.questionAnswer(question, answerNo) == '0') {
      HTML
        += `<button class="menubutton" style="font-size : 150%;color:red;"\n\
                        id="qButton" \n\
                        type="button" \n\\n\
                        data-answered="yes" \n\
                        ${ID
}\n\
                        onclick="utils.updateAnswer(this,0);">&#128078;</button>`;
    } else {
      HTML
        += `<button class="menubutton" style="font-size : 150%;color:red;"\n\
                        id="qButton" \n\
                        type="button" \n\
                        ${ID
}\n\
                        onclick="utils.updateAnswer(this,0);">&#128078;</button>`;
    }
    if (this.questionAnswer(question, answerNo) == '2') {
      HTML
        += `<button class="menubutton" style="font-size : 150%;color:green;"\n\
                        id="qButton" \n\
                        type="button" \n\\n\
                        data-answered="yes" \n\
                        ${ID
}\n\
                        onclick="utils.updateAnswer(this,2);">?</button>`;
    } else {
      HTML
        += `<button class="menubutton" style="font-size : 150%;color:green;"\n\
                        id="qButton" \n\
                        type="button" \n\
                        ${ID
}\n\
                        onclick="utils.updateAnswer(this,2);">?</button>`;
    }

    HTML += this.HTMLFooter(question, answerNo);
    return HTML;

    // <input type="radio" name="gender" value="male"> Male<br>
  }

  groupheading(ID: string, question: Question, answerNo?: number) {
    // const answer = this.questionAnswer(question, answerNo);
    /*
    onst HTML = `${this.HTMLheader(question)
    }<button class="menubutton" ${ID} style="font-size : 65%;float: left;font-family: LigatureSymbols;" type="button" onclick=" ${answer != '1'
      ? 'utils.updateAnswer(this,1);'
      : 'utils.updateAnswer(this,0);'
    }">${answer == '1' ? '&#xE108;' : '&#xE08f;'}</button>`
            '<button class="menubutton" ' +
      ID +
      ' style="font-size : 65%;float: left;font-family: LigatureSymbols;" type="button" onclick="utils.showOnlyInstance(this);">' +
      '&#xE121;' + // '&#xE122;' +
      '</button>' +
  */
    const HTML = `${this.HTMLheader(question)}
    <div style="font-size : 100%;float: right;visibility:hidden;">${window.activelanguage == 'NOR'
    ? 'Bekreft sletting:'
    : 'Confirm deletion:'
}<input id="deletecheck" type="checkbox" ${ID
} onChange="if (this.checked===true) {this.holdRendering=false;utils.deleteGroup(this.dataset);}"></div>`
      + `<button class="menubutton" id="deletebutton" ${ID
      } style="font-size : 65%;float: right;visibility:${this.readonly(question) ? 'hidden' : 'visible'
      };font-family: LigatureSymbols;" type="button" onClick="utils.confirmDeleteHeader(this.dataset);">&#xE12c;</button>`;
    return HTML + this.HTMLFooter(question, answerNo);
  }

  orderheading(ID: string, question: Question, answerNo?: number) {
    const HTML = `${this.HTMLheader(question)
    }<input type="checkbox" ${ID
    } style="float:right;font-size : 100%;visibility:hidden;font-family: LigatureSymbols;;margin:0px">`
      + '<button style="font-size : 100%;font-family: LigatureSymbols;visibility:hidden;margin:0px" type="button" onClick="">&#xE038;</button>'
      + `<label id="line1">${typeof this.questionAnswer(question, answerNo) === 'undefined'
        ? ''
        : this.questionAnswer(question, answerNo)
      }</label>`; //+
    return HTML + this.HTMLFooter(question, answerNo);
  }

  barcodescan(ID: string, question: Question, answerNo?: number) {
    const HTML = this.HTMLheader(question);
    // npm install --save @ericblade/quagga2
    return HTML + this.HTMLFooter(question, answerNo);
  }
}
